
import { defineComponent } from 'vue'
import TopScreen from '@/components/TopScreen.vue'
import TourSelect from '@/components/TourSelect.vue'
import TourListSelected from '@/components/TourListSelected.vue'
import QuestionnaireFillOut from '@/components/QuestionnaireFillOut.vue'
import QuestionnaireShow from '@/components/QuestionnaireShow.vue'
import Coupon from '@/components/Coupon.vue'
import WaitingTimeSection from '@/components/WaitingTimeSection.vue'
import Greeting from '@/components/Greeting.vue'
import AreaMap from '@/components/AreaMap.vue'
import FreeWifi from '@/components/FreeWifi.vue'
import ArFilter from '@/components/ArFilter.vue'
import Coupons from '@/components/Coupons.vue'
import JoinUs from '@/components/JoinUs.vue'
import MemberInfo from '@/components/debug/MemberInfo.vue'
import { retrieveUser, saveUser } from '@/api/user'
import { retrieveCouponUser, saveCouponUser } from '@/api/coupon_user'
import { retrieveQuestionnaire, saveQuestionnaire } from '@/api/questionnaire'
import { ScreenName } from '@/types/ScreenName'
// import { PagePath } from '@/types/PagePath'
import { CouponBannerUser, User } from '@/types/User'
import { handleSignatureObjectFromUrlParams } from '@/helpers/signatureObject'
import { SignatureObject } from '@/types/SignatureObject'
import { consoleLog } from '@/api/consoleLog'
import { DATE_FORMAT, DAYS_OF_THE_WEEK, POSSIBLE_ORIGINS } from '@/constants'
import { NewQuestionnaire, QuestionnaireToDisplay } from '@/types/Questionnaire'
import { Step } from '@/types/Step'
import { format, getDay } from 'date-fns'
import { Tour } from '@/types/Tour'
import { retrieveWaitingTime } from '@/api/waiting_time'
import { WaitingTime } from '@/types/WaitingTime'
import { retrieveUpdatedTime } from '@/api/updated_time'
import { UpdatedTime } from '@/types/UpdatedTime'
import Tour2024 from './tour_2024/Tour2024.vue'

declare global {
  interface Window {
    qr6d1bjpc11z7c4: string | undefined
  }
}

export default defineComponent({
  name: 'TopPage',

  components: {
    TopScreen,
    TourSelect,
    TourListSelected,
    Tour2024,
    QuestionnaireFillOut,
    QuestionnaireShow,
    Coupon,
    WaitingTimeSection,
    Greeting,
    AreaMap,
    FreeWifi,
    ArFilter,
    Coupons,
    JoinUs,
    MemberInfo,
  },

  props: {
    pagePath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      nodeEnvironment: 'development',
      currentComponentName: 'top_screen' as ScreenName,
      currentUser: {
        objectId: '',
        memberNumber: '',
        hasUsedDrinkCoupon: false,
        hasUsedGoodsCoupon: false,
        hasUsedCoupon: false,
        isPremiumMember: false,
        selectedTourIds: [],
        tourOptions: [],
        todayTourId: null,
        premiumMemberStartedAt: undefined,
      } as User,
      hasCheckedForQuestionnaireToDisplay: false,
      questionnaireToDisplay: {
        tourId: null,
        isQuestionnairePresent: false,
        name: '',
      } as QuestionnaireToDisplay,
      signatureObject: {
        signature: '',
        signedData: {},
      } as SignatureObject,
      hasCheckedForFcno: false,
      isCouponBanner: false,
      fcnoMemberNumber: '',
      couponBannerUser: {
        memberNumber: '',
        hasUsedCoupon: false,
      } as CouponBannerUser,
      couponImage: {
        goods: {
          first: "goods_coupon_chiba03",
          second: "goods_coupon_chiba04",
        },
        drink: 'drink_coupon_chiba',
      },
      waitingTime: {} as WaitingTime,
      updatedTime: {} as UpdatedTime,
      today: new Date(),
      targetDay: new Date("2024-05-04"),
    }
  },

  computed: {
    isAppLoaded(): boolean {
      return this.currentUser.objectId !== ''
    },

    canDisplayNonAppLoadedParts(): boolean {
      return this.hasCheckedForFcno
    },

    isTopScreen(): boolean {
      return this.pagePath === '/'
    },

    isTourSelect(): boolean {
      return this.currentComponentName === 'tour_select'
    },

    isTourListSelected(): boolean {
      return this.currentComponentName === 'tour_list_selected'
    },

    isQuestionnaireFillOut(): boolean {
      return this.currentComponentName === 'questionnaire_fill_out'
    },

    isQuestionnaireShow(): boolean {
      return this.currentComponentName === 'questionnaire_show'
    },

    isCoupon(): boolean {
      return this.pagePath === '/coupon'
    },

    currentStep(): Step {
      if (
        this.currentUser.objectId === '' ||
        this.selectedTourIdsOfCurrentOptions.length < 1
      ) {
        return 'step_a_select_tours'
      } else if (
        this.currentUser.todayTourId === null ||
        !this.selectedTourIdsOfCurrentOptions.some(
          (tourId) => tourId === this.currentUser.todayTourId
        )
      ) {
        return 'step_b_wait_for_tour'
      } else if (
        this.questionnaireToDisplay &&
        !this.questionnaireToDisplay.isQuestionnairePresent
      ) {
        return 'step_c_fill_out_questionnaire'
      } else if (
        this.questionnaireToDisplay &&
        this.questionnaireToDisplay.isQuestionnairePresent
      ) {
        return 'step_d_show_questionnaire'
      } else {
        return 'step_a_select_tours'
      }
    },

    selectedTourIdsOfCurrentOptions(): number[] {
      const { selectedTourIds, tourOptions } = this.currentUser
      const tourOptionIds = tourOptions.map(({ tourId }) => tourId)
      return selectedTourIds.filter((tourId) => tourOptionIds.includes(tourId))
    },

    selectedTourIdsOfOldOptions(): number[] {
      const { selectedTourIds, tourOptions } = this.currentUser
      const tourOptionIds = tourOptions.map(({ tourId }) => tourId)
      return selectedTourIds.filter((tourId) => !tourOptionIds.includes(tourId))
    },

    tourOptionsComputed(): Tour[] {
      const tourOptions = (this.currentUser as User).tourOptions
      return tourOptions.map((tour) => {
        const dateObject = new Date(tour.date)
        const day = getDay(dateObject)
        return {
          ...tour,
          dateObject,
          checked: this.currentUser.selectedTourIds.includes(tour.tourId),
          dateString: format(dateObject, DATE_FORMAT),
          dayOfTheWeek: DAYS_OF_THE_WEEK[day],
        }
      })
    },

    todayTour(): Tour | null {
      if (this.currentUser.todayTourId) {
        const tour = this.tourOptionsComputed.find(
          (tour) => tour.tourId === this.questionnaireToDisplay.tourId
        )
        return tour === undefined ? null : tour
      } else {
        return null
      }
    },

    wrapperClassComputed() {
      const array: string[] = []
      if (this.currentComponentName === 'coupon') {
        array.push('background-grey')
      }
      return array
    },
  },

  created() {
    setTimeout(() => {
      this.startApp()
    }, 50)
  },

  methods: {
    startApp() {
      this.nodeEnvironment = process.env.NODE_ENV || 'development'
      this.checkForFcno()
      if (this.isCouponBanner) {
        this.executeCouponBanner()
        return
      }
      this.hasCheckedForFcno = true
      const { signature, signedData } =
        handleSignatureObjectFromUrlParams(window)
      this.signatureObject = { signature, signedData }
      this.retrieveUser()
      this.retrieveWaitingTime()
      this.retrieveUpdatedTime()
      if (this.nodeEnvironment !== 'development') {
        window.addEventListener('message', this.receiveMessage, false)
      }
    },

    checkForFcno() {
      const fcno = window.qr6d1bjpc11z7c4
      if (!fcno) {
        return
      }
      this.isCouponBanner = true
      this.fcnoMemberNumber = fcno
    },

    receiveMessage(
      event: MessageEvent<{
        [k: string]: string
      }>
    ) {
      if (!POSSIBLE_ORIGINS.includes(event.origin)) {
        return
      }
      const { signature, signedData } = event.data
      consoleLog({
        log: 'message received',
        origin: event.origin,
        data: event.data,
        signature,
        signedData,
      })
      this.signatureObject = { signature, signedData }
      this.retrieveUser()
    },

    navigateTo(screenName: ScreenName) {
      this.currentComponentName = screenName
      window.scrollTo(0, 0)
    },

    async retrieveUser() {
      const data = await retrieveUser(this.signatureObject)
      if (data) {
        this.currentUser = data
        const todayTourId = this.currentUser.todayTourId
        if (todayTourId) {
          await this.retrieveQuestionnaire(todayTourId)
        }
        this.hasCheckedForQuestionnaireToDisplay = true
      }
    },

    async retrieveWaitingTime() {
      const data = await retrieveWaitingTime(this.signatureObject)
      if (data) {
        this.waitingTime = data
      }
    },

    async retrieveUpdatedTime() {
      const data = await retrieveUpdatedTime(this.signatureObject)
      if (data) {
        this.updatedTime = data
      }
    },

    async saveSelectedTourIds(selectedTourIdsNew: number[]) {
      this.currentUser.selectedTourIds =
        this.selectedTourIdsOfOldOptions.concat(selectedTourIdsNew)
      await this.saveUser()
      this.navigateTo('tour_list_selected')
    },

    async useDrinkCoupon() {
      if (!this.isCouponBanner) {
        await this.saveDrinkUser(true)
      } else {
        await this.saveCouponUser()
      }
    },

    async useGoodsCoupon() {
      if (!this.isCouponBanner) {
        await this.saveGoodsUser(true)
      } else {
        await this.saveCouponUser()
      }
    },

    async saveUser(
      useDrinkCoupon: boolean = false,
      useGoodsCoupon: boolean = false
    ) {
      const data = await saveUser(
        this.currentUser.selectedTourIds,
        useDrinkCoupon,
        useGoodsCoupon,
        this.signatureObject
      )
      if (data) {
        this.currentUser = data
      }
    },

    async saveDrinkUser(useDrinkCoupon: boolean = false) {
      const data = await saveUser(
        this.currentUser.selectedTourIds,
        useDrinkCoupon,
        false,
        this.signatureObject
      )
      if (data) {
        this.currentUser = data
      }
    },

    async saveGoodsUser(useGoodsCoupon: boolean = false) {
      const data = await saveUser(
        this.currentUser.selectedTourIds,
        false,
        useGoodsCoupon,
        this.signatureObject
      )
      if (data) {
        this.currentUser = data
      }
    },

    async retrieveQuestionnaire(tourId: number) {
      const data = await retrieveQuestionnaire(tourId, this.signatureObject)
      if (data) {
        this.setQuestionnaireToDisplay(data)
      }
    },

    async saveQuestionnaire(questionnaire: NewQuestionnaire): Promise<void> {
      const data = await saveQuestionnaire(questionnaire, this.signatureObject)
      if (data) {
        this.setQuestionnaireToDisplay(data)
      }
      this.navigateTo('questionnaire_show')
    },

    setQuestionnaireToDisplay(questionnaire: QuestionnaireToDisplay) {
      const { tourId, isQuestionnairePresent, name } = questionnaire
      this.questionnaireToDisplay.tourId = tourId
      this.questionnaireToDisplay.isQuestionnairePresent =
        isQuestionnairePresent
      this.questionnaireToDisplay.name = name
    },

    async executeCouponBanner() {
      this.navigateTo('coupon')
      await this.retrieveCouponUser()
    },

    async retrieveCouponUser() {
      const data = await retrieveCouponUser(this.fcnoMemberNumber)
      if (data) {
        this.setCouponUser(data)
      }
    },

    async saveCouponUser() {
      const data = await saveCouponUser(
        this.couponBannerUser.memberNumber,
        true
      )
      if (data) {
        this.setCouponUser(data)
      }
    },

    setCouponUser(couponUser: CouponBannerUser) {
      const { memberNumber, hasUsedCoupon } = couponUser
      this.couponBannerUser.memberNumber = memberNumber
      this.couponBannerUser.hasUsedCoupon = hasUsedCoupon
    },

    dateDetermination() {
      return this.today <= this.targetDay
    },
  },
})
