
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WaitingTime',

  props: {
    waitingTime: {
      type: Object,
      required: true,
    },

    updatedTime: {
      type: Object,
    },
  },
})
