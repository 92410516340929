
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Greeting',
  data() {
    return {
      sections: [
        {
          name: 'DAY 1',
          times: [
            { time: '14:00 ～ 14:15' },
            { time: '15:00 ～ 15:15' },
            { time: '16:00 ～ 16:15' },
          ],
        },
        {
          name: 'DAY 2',
          times: [
            { time: '15:00 ～ 15:15' },
            { time: '16:00 ～ 16:15' },
            { time: '17:00 ～ 17:15' },
          ],
        }
      ]
    }
  },
})
