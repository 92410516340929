
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AreaMap',

  data() {
    return {
      show: false,
    }
  },

  methods: {
    showModal(): boolean {
      return (this.show = true)
    },

    closeModal(): boolean {
      return (this.show = false)
    },
  },
})
