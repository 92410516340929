
import { defineComponent } from 'vue'
import { Field } from 'vee-validate'

export default defineComponent({
  name: 'AppInputText',

  components: {
    Field,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    label: {
      type: String,
      default: null,
    },

    sublabel: {
      type: String,
      default: null,
    },

    displayError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: '',
      validator: (value: string) => ['', 'small', 'tiny'].includes(value),
    },
  },

  emits: ['update:modelValue', 'touched'],

  methods: {
    onBlur() {
      this.$emit('touched')
    },
  },
})
