
import { ScreenName } from '@/types/ScreenName'
import { defineComponent, PropType } from 'vue'
import { Step } from '@/types/Step'
import { Tour } from '@/types/Tour'

function getScreenNameForActionBasedOnStep(step: Step): ScreenName {
  switch (step) {
    case 'step_a_select_tours':
      return 'tour_select'
    case 'step_b_wait_for_tour':
      return 'tour_list_selected'
    case 'step_c_fill_out_questionnaire':
      return 'questionnaire_fill_out'
    case 'step_d_show_questionnaire':
      return 'questionnaire_show'
  }
}

export default defineComponent({
  name: 'TopScreen',

  props: {
    canDisplayNonAppLoadedParts: {
      type: Boolean,
      required: true,
    },

    isAppLoaded: {
      type: Boolean,
      required: true,
    },

    step: {
      type: String as PropType<Step>,
      required: true,
    },

    todayTour: {
      type: Object as PropType<Tour | null>,
      default: null,
    },
  },

  emits: ['navigate'],

  computed: {
    buttonText(): string {
      switch (this.step) {
        case 'step_a_select_tours':
          return '参加日を入力する'
        case 'step_b_wait_for_tour':
          return '参加日一覧を見る'
        case 'step_c_fill_out_questionnaire':
          return '問診票を入力する'
        case 'step_d_show_questionnaire':
          return '問診票を提示する'
        default:
          return ''
      }
    },

    isStepDShowQuestionnaire() {
      return this.step === 'step_d_show_questionnaire'
    },

    showLinkTourListSelected() {
      return (
        this.step !== 'step_a_select_tours' &&
        this.step !== 'step_b_wait_for_tour'
      )
    },
  },

  methods: {
    navigateToStepAction() {
      this.$emit('navigate', getScreenNameForActionBasedOnStep(this.step))
    },

    navigateToTourListSelected() {
      this.$emit('navigate', 'tour_list_selected' as ScreenName)
    },

    navigateToCoupon() {
      this.$emit('navigate', 'coupon' as ScreenName)
    },
  },
})
