
import { defineComponent, PropType } from 'vue'
import TheHeaderWithCloseButton from '@/components/TheHeaderWithCloseButton.vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'
import { ScreenName } from '@/types/ScreenName'
import { QuestionnaireToDisplay } from '@/types/Questionnaire'
import { Tour } from '@/types/Tour'

export default defineComponent({
  name: 'QuestionnaireShow',

  components: {
    TheHeaderWithCloseButton,
    AppButtonPrimary,
    AppButtonSecondary,
  },

  props: {
    questionnaire: {
      type: Object as PropType<QuestionnaireToDisplay>,
      required: true,
    },

    todayTour: {
      type: Object as PropType<Tour | null>,
      required: true,
    },
  },

  emits: ['navigate'],

  computed: {
    showScreen() {
      return this.questionnaire.isQuestionnairePresent
    },

    returnScreen(): ScreenName {
      return 'top_screen'
    },
  },

  methods: {
    close() {
      this.$emit('navigate', this.returnScreen)
    },

    toQuestionnaireFillOut() {
      this.$emit('navigate', 'questionnaire_fill_out')
    },
  },
})
