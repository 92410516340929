
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MemberInfo',

  props: {
    currentUser: {} as any,
  },

  data() {
    return {
      showTourOptions: false,
    }
  },

  computed: {
    href() {
      return location.href
    },
  },

  methods: {
    handleShowOptions() {
      this.showTourOptions = !this.showTourOptions
    },
  },
})
