
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TourSelectSelectedTourDate',

  props: {
    dateString: {
      type: String,
      required: true,
    },

    dayOfTheWeek: {
      type: String,
      required: true,
    },

    location: {
      type: String,
      required: true,
    },

    isHoliday: {
      type: Boolean,
      required: true,
    },
  },
})
