import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c152219"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "caret"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({ active: _ctx.active }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button')))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.showCaret)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, ">"))
      : _createCommentVNode("", true)
  ], 2))
}