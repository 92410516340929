import { axios } from './axios'

export const consoleLog = async (log: {
  [k: string]: Object
}): Promise<void> => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_CONSOLE_LOG_API}`, {
      params: {
        log,
      },
    })
    return response.data
  } catch (error) {
    console.error('consoleLog', error)
    throw error
  }
}
