import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e3f4026"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label"
}
const _hoisted_2 = {
  key: 1,
  class: "sublabel"
}
const _hoisted_3 = {
  key: 2,
  class: "error-contents"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input", _ctx.size])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.sublabel)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.sublabel), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Field, {
      name: _ctx.name,
      value: _ctx.modelValue,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      as: "input",
      rules: _ctx.rules,
      "validate-on-input": true,
      class: _normalizeClass({ 'display-error': _ctx.displayError }),
      onblur: _ctx.onBlur,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 8, ["name", "value", "type", "placeholder", "rules", "class", "onblur"]),
    (_ctx.displayError && _ctx.errorMessage !== '')
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 2))
}