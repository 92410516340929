import axiosLibrary from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
const nodeEnvironment = process.env.NODE_ENV || 'development'

// console.log("Setup axios", nodeEnvironment);
const instance = axiosLibrary.create({
  headers: {
    'Content-Type': 'application/json',
  },
  ...(nodeEnvironment === 'development' ||
  nodeEnvironment === 'staging_development'
    ? { baseURL: 'http://localhost:8080' }
    : {}), // only for use when serve vue on 8082
})
instance.interceptors.request.use((config) => {
  const newConfig = { ...config }
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }
  return newConfig
})
instance.interceptors.response.use((response) => {
  if (response.data) {
    response.data = camelizeKeys(response.data)
  }
  return response
})
export const axios = instance
