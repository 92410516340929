import { SignatureObject } from '@/types/SignatureObject'
import { UpdatedTime } from '@/types/UpdatedTime'
import { axios } from './axios'

export const retrieveUpdatedTime = async (
  signatureObject: SignatureObject
): Promise<UpdatedTime | undefined> => {
  const { signature, signedData } = signatureObject
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_UPDATED_TIME_API}/`,
      {
        params: { signature, signedData },
      }
    )
    return response.data
  } catch (error) {
    console.error('retrieveUpdatedTime', error)
    throw error
  }
}
