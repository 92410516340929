import { SignatureObject } from '@/types/SignatureObject'

export const handleSignatureObjectFromUrlParams = (
  window: Window
): UrlParamsData => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const { signature, signed_data: signedData } = params
  return {
    signature,
    signedData,
  }
}

interface UrlParamsData extends SignatureObject {}
