
import { defineComponent, PropType } from 'vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import { Tour } from '@/types/Tour'
import { getDate, getMonth } from 'date-fns'

export default defineComponent({
  name: 'TourListSelectedSectionTourDate',

  components: {
    AppButtonPrimary,
  },

  props: {
    tour: {
      type: Object as PropType<Tour>,
      required: true,
    },

    active: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['button'],

  computed: {
    month() {
      return getMonth(this.tour.dateObject) + 1
    },

    date() {
      return getDate(this.tour.dateObject)
    },

    questionnaireFilledOut() {
      return true
    },
  },

  methods: {
    toQuestionnaire() {
      if (this.active) {
        this.$emit('button')
      }
    },
  },
})
