import { SignatureObject } from '@/types/SignatureObject'
import { User } from '@/types/User'
import { axios } from './axios'

interface UserGetParams {
  tourId: number
  signature: string
  signedData: any
}

export const retrieveUser = async (
  signatureObject: SignatureObject
): Promise<User | undefined> => {
  const { signature, signedData } = signatureObject
  try {
    const response = await axios.get(`${process.env.VUE_APP_USERS_API}/_`, {
      params: { signature, signedData } as UserGetParams,
    })
    return response.data
  } catch (error) {
    console.error('retrieveUser', error)
    throw error
  }
}

interface UserPostParams {
  selectedTourIds: number[]
  useDrinkCoupon: boolean
  useGoodsCoupon: boolean
  signature: string
  signedData: any
}

export const saveUser = async (
  selectedTourIds: number[],
  useDrinkCoupon: boolean,
  useGoodsCoupon: boolean,
  signatureObject: SignatureObject
): Promise<User | undefined> => {
  const { signature, signedData } = signatureObject
  try {
    const response = await axios.patch<User>(
      `${process.env.VUE_APP_USERS_API}`,
      {
        selectedTourIds,
        useDrinkCoupon,
        useGoodsCoupon,
        signature,
        signedData,
      } as UserPostParams
    )
    return response.data
  } catch (error) {
    console.error('saveUser', error)
    throw error
  }
}
