
import { defineComponent, PropType } from 'vue'
import TheHeaderWithCloseButton from '@/components/TheHeaderWithCloseButton.vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'
import TourListSelectedSectionTourDate from '@/components/tour_list_selected/TourListSelectedSectionTourDate.vue'
import { Tour } from '@/types/Tour'
import { ScreenName } from '@/types/ScreenName'
import { QuestionnaireToDisplay } from '@/types/Questionnaire'

export default defineComponent({
  name: 'TourListSelected',

  components: {
    TheHeaderWithCloseButton,
    AppButtonPrimary,
    AppButtonSecondary,
    TourListSelectedSectionTourDate,
  },

  props: {
    selectedTourIds: {
      type: Array as PropType<number[]>,
      required: true,
    },

    tourOptions: {
      type: Array as PropType<Tour[]>,
      required: true,
    },

    questionnaireToDisplay: {
      type: Object as PropType<QuestionnaireToDisplay>,
      required: true,
    },
  },

  emits: ['navigate'],

  computed: {
    tourOptionsComputed() {
      return this.tourOptions.filter(({ checked }) => checked)
    },
  },

  methods: {
    close() {
      const screenName: ScreenName = 'top_screen'
      this.$emit('navigate', screenName)
    },

    navigateToTourSelect() {
      const screenName: ScreenName = 'tour_select'
      this.$emit('navigate', screenName)
    },

    navigateToQuestionnaire() {
      const screenName: ScreenName = !this.questionnaireToDisplay
        .isQuestionnairePresent
        ? 'questionnaire_fill_out'
        : 'questionnaire_show'
      this.$emit('navigate', screenName)
    },
  },
})
