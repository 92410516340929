
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppInputCheckbox',

  props: {
    checked: {
      type: Boolean,
      required: true,
    },

    displayError: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['check'],
})
