
import { defineComponent } from 'vue'
import CouponStepANoticeNonMember from '@/components/coupon/CouponStepANoticeNonMember.vue'
import CouponStepBNoticeInstructionsBeforeUse from '@/components/coupon/CouponStepBNoticeInstructionsBeforeUse.vue'
import CouponStepCNoticeWithConfirmUse from '@/components/coupon/CouponStepCNoticeWithConfirmUse.vue'
import CouponStepDMessageThanks from '@/components/coupon/CouponStepDMessageThanks.vue'
import { ScreenName } from '@/types/ScreenName'

const couponSteps = [
  'coupon_step_a_non_member',
  'coupon_step_b_member_waiting_on_coupon_use',
  'coupon_step_c_member_waiting_on_press_use_button',
  'coupon_step_d_member_coupon_used',
] as const
type CouponStep = typeof couponSteps[number]

export default defineComponent({
  name: 'Coupon',

  components: {
    CouponStepANoticeNonMember,
    CouponStepBNoticeInstructionsBeforeUse,
    CouponStepCNoticeWithConfirmUse,
    CouponStepDMessageThanks,
  },

  props: {
    isAppLoaded: {
      type: Boolean,
      required: true,
    },

    showHeader: {
      type: Boolean,
      required: true,
    },

    isPremiumMember: {
      type: Boolean,
      required: true,
    },

    hasUsedCoupon: {
      type: Boolean,
      required: true,
    },

    coupon_type: {
      type: String,
    },

    coupon_image: {
      type: String,
    },
  },

  emits: ['navigate', 'useCoupon'],

  data() {
    return {
      currentStepIndex: 0,
      isConfirmCheckboxChecked: false,
    }
  },

  computed: {
    currentStepName(): CouponStep {
      return couponSteps[this.currentStepIndex]
    },

    isCouponStepANonMember() {
      return this.currentStepName === 'coupon_step_a_non_member'
    },

    isCouponStepBMemberWaitingOnCouponUse() {
      return (
        this.currentStepName === 'coupon_step_b_member_waiting_on_coupon_use'
      )
    },

    isCouponStepCMemberWaitingOnPressUseButton() {
      return (
        this.currentStepName ===
        'coupon_step_c_member_waiting_on_press_use_button'
      )
    },

    isCouponStepDMemberCouponUsed() {
      return this.currentStepName === 'coupon_step_d_member_coupon_used'
    },

    returnScreen(): ScreenName {
      return 'top_screen'
    },

    getUsingCouponImage() {
      return require(`@/assets/${this.coupon_image}.png`)
    },

    getUsedCouponImage() {
      return require(`@/assets/${this.coupon_image}_used.png`)
    },
  },

  watch: {
    hasUsedCoupon() {
      this.currentStepIndex = 3
    },
  },

  mounted() {
    if (this.isPremiumMember) {
      this.currentStepIndex++
    }
    if (this.hasUsedCoupon) {
      this.currentStepIndex = 3
    }
  },

  methods: {
    close() {
      this.$emit('navigate', this.returnScreen)
    },

    clickUseButton() {
      if (this.isCouponStepBMemberWaitingOnCouponUse) {
        this.currentStepIndex++
      } else if (
        this.isCouponStepCMemberWaitingOnPressUseButton &&
        this.isConfirmCheckboxChecked
      ) {
        this.$emit('useCoupon')
        this.currentStepIndex = 3
      }
    },

    toggleConfirmCheckbox() {
      this.isConfirmCheckboxChecked = !this.isConfirmCheckboxChecked
    },
  },
})
