
import { defineComponent } from 'vue'
import TheHeaderWithCloseButton from '@/components/TheHeaderWithCloseButton.vue'
import QuestionnaireFillOutStepAFillOut from '@/components/questionnaire_fill_out/QuestionnaireFillOutStepAFillOut.vue'
import QuestionnaireFillOutStepBConfirm from '@/components/questionnaire_fill_out/QuestionnaireFillOutStepBConfirm.vue'
import { ScreenName } from '@/types/ScreenName'
import { NewQuestionnaire } from '@/types/Questionnaire'

const questionnaireFillOutSteps = [
  'questionnaire_fill_out_step_a_select',
  'questionnaire_fill_out_step_b_confirm',
] as const
type QuestionnaireFillOutStep = typeof questionnaireFillOutSteps[number]

export default defineComponent({
  name: 'QuestionnaireFillOut',

  components: {
    TheHeaderWithCloseButton,
    QuestionnaireFillOutStepAFillOut,
    QuestionnaireFillOutStepBConfirm,
  },

  props: {
    tourId: {
      type: Number,
      required: true,
    },
  },

  emits: ['navigate', 'saveQuestionnaire'],

  data() {
    return {
      currentStepIndex: 0,

      name: '',
      email: '',
      phoneNumber: '',
      prefecture: '',
      birthday: '',
      gender: '',
      confirmsIsInGoodHealth: false,
      agreesToPrivatePolicy: false,
    }
  },

  computed: {
    isQuestionnaireFillOutStepAFillOut() {
      return this.currentStepName === 'questionnaire_fill_out_step_a_select'
    },

    isQuestionnaireFillOutStepBConfirm() {
      return this.currentStepName === 'questionnaire_fill_out_step_b_confirm'
    },

    currentStepName(): QuestionnaireFillOutStep {
      return questionnaireFillOutSteps[this.currentStepIndex]
    },

    totalSteps() {
      return questionnaireFillOutSteps.length
    },

    returnScreen(): ScreenName {
      return 'top_screen'
    },

    newQuestionnaire(): NewQuestionnaire {
      const {
        tourId,
        name,
        email,
        phoneNumber,
        prefecture,
        birthday,
        gender,
        confirmsIsInGoodHealth,
        agreesToPrivatePolicy,
      } = this
      return {
        tourId,
        name,
        email,
        phoneNumber,
        prefecture,
        birthday,
        gender,
        confirmsIsInGoodHealth,
        agreesToPrivatePolicy,
      }
    },
  },

  methods: {
    resetData() {
      this.name = ''
      this.email = ''
      this.phoneNumber = ''
      this.prefecture = ''
      this.birthday = ''
      this.gender = ''
      this.confirmsIsInGoodHealth = false
      this.agreesToPrivatePolicy = false
    },

    close() {
      this.$emit('navigate', this.returnScreen)
      this.resetData()
    },

    save(data: Omit<NewQuestionnaire, 'tourId'>) {
      const {
        name,
        email,
        phoneNumber,
        prefecture,
        birthday,
        gender,
        confirmsIsInGoodHealth,
        agreesToPrivatePolicy,
      } = data
      this.name = name
      this.email = email
      this.phoneNumber = phoneNumber
      this.prefecture = prefecture
      this.birthday = birthday
      this.gender = gender
      this.confirmsIsInGoodHealth = confirmsIsInGoodHealth
      this.agreesToPrivatePolicy = agreesToPrivatePolicy
      this.next()
    },

    next() {
      if (this.currentStepIndex < this.totalSteps - 1) {
        window.scrollTo(0, 0)
        this.currentStepIndex++
      } else {
        const {
          tourId,
          name,
          email,
          phoneNumber,
          prefecture,
          birthday,
          gender,
          confirmsIsInGoodHealth,
          agreesToPrivatePolicy,
        } = this
        const newQuestionnaire: NewQuestionnaire = {
          tourId,
          name,
          email,
          phoneNumber,
          prefecture,
          birthday,
          gender,
          confirmsIsInGoodHealth,
          agreesToPrivatePolicy,
        }
        this.$emit('saveQuestionnaire', newQuestionnaire)
      }
    },

    previous() {
      if (this.currentStepIndex > 0) {
        window.scrollTo(0, 0)
        this.currentStepIndex--
      } else {
        this.$emit('navigate', this.returnScreen)
        this.resetData()
      }
    },
  },
})
