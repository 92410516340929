import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f4760d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-tour-date" }
const _hoisted_2 = { class: "date" }
const _hoisted_3 = { class: "day-location" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dateString), 1),
    _createElementVNode("div", _hoisted_3, " (" + _toDisplayString(_ctx.dayOfTheWeek) + _toDisplayString(_ctx.isHoliday ? '･祝' : '') + ") / " + _toDisplayString(_ctx.location), 1)
  ]))
}