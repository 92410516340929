import { SignatureObject } from '@/types/SignatureObject'
import { WaitingTime } from '@/types/WaitingTime'
import { axios } from './axios'

export const retrieveWaitingTime = async (
  signatureObject: SignatureObject
): Promise<WaitingTime | undefined> => {
  const { signature, signedData } = signatureObject
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_WAITING_TIME_API}/`,
      {
        params: { signature, signedData },
      }
    )
    const sortData = response.data.sort((a: any, b: any) => {
      return a.sortOrder - b.sortOrder
    })
    return sortData
  } catch (error) {
    console.error('retrieveWaitingTime', error)
    throw error
  }
}
