import { SignatureObject } from '@/types/SignatureObject'
import { NewQuestionnaire, QuestionnaireToDisplay } from '@/types/Questionnaire'
import { axios } from './axios'

interface QuestionnaireGetParams {
  tourId: number
  signature: string
  signedData: any
}

export const retrieveQuestionnaire = async (
  tourId: number,
  signatureObject: SignatureObject
): Promise<QuestionnaireToDisplay | undefined> => {
  const { signature, signedData } = signatureObject
  try {
    const response = await axios.get<QuestionnaireToDisplay>(
      `${process.env.VUE_APP_QUESTIONNAIRES_API}/_`,
      {
        params: { tourId, signature, signedData } as QuestionnaireGetParams,
      }
    )
    return response.data
  } catch (error) {
    console.error('retrieveQuestionnaire', error)
    throw error
  }
}

interface QuestionnairePostParams {
  questionnaire: NewQuestionnaire
  confirmsIsInGoodHealth: boolean
  agreesToPrivatePolicy: boolean
  signature: string
  signedData: any
}

export const saveQuestionnaire = async (
  questionnaire: NewQuestionnaire,
  signatureObject: SignatureObject
): Promise<QuestionnaireToDisplay | undefined> => {
  const { signature, signedData } = signatureObject
  try {
    const response = await axios.post<QuestionnaireToDisplay>(
      `${process.env.VUE_APP_QUESTIONNAIRES_API}`,
      {
        questionnaire,
        confirmsIsInGoodHealth: true,
        agreesToPrivatePolicy: true,
        signature,
        signedData,
      } as QuestionnairePostParams
    )
    return response.data
  } catch (error) {
    console.error('saveQuestionnaire', error)
    throw error
  }
}
