import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0118a1e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "tour-2024" }
const _hoisted_2 = { id: "coupons" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  href: "#/debug"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tour2024 = _resolveComponent("Tour2024")!
  const _component_Coupons = _resolveComponent("Coupons")!
  const _component_JoinUs = _resolveComponent("JoinUs")!
  const _component_Coupon = _resolveComponent("Coupon")!
  const _component_MemberInfo = _resolveComponent("MemberInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.pagePath === '/')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "wrapper",
          class: _normalizeClass(_ctx.wrapperClassComputed)
        }, [
          _createElementVNode("section", _hoisted_1, [
            _createVNode(_component_Tour2024)
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_component_Coupons, {
              "is-premium-member": _ctx.isCouponBanner || _ctx.currentUser.isPremiumMember,
              "created-at": _ctx.currentUser.premiumMemberStartedAt
            }, null, 8, ["is-premium-member", "created-at"])
          ]),
          _createVNode(_component_JoinUs)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.pagePath === '/drink_coupon' && _ctx.isAppLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Coupon, {
            "show-header": !_ctx.isCouponBanner,
            "is-premium-member": _ctx.isCouponBanner || _ctx.currentUser.isPremiumMember,
            "has-used-coupon": _ctx.currentUser.hasUsedDrinkCoupon,
            "is-app-loaded": 
        _ctx.isAppLoaded || (_ctx.isCouponBanner && _ctx.couponBannerUser.memberNumber !== '')
      ,
            coupon_type: 'drink',
            coupon_image: _ctx.couponImage.drink,
            onNavigate: _ctx.navigateTo,
            onUseCoupon: _ctx.useDrinkCoupon
          }, null, 8, ["show-header", "is-premium-member", "has-used-coupon", "is-app-loaded", "coupon_image", "onNavigate", "onUseCoupon"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.pagePath === '/debug' && _ctx.nodeEnvironment !== 'production')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_MemberInfo, { "current-user": _ctx.currentUser }, null, 8, ["current-user"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.nodeEnvironment !== 'production')
      ? (_openBlock(), _createElementBlock("a", _hoisted_5, "デバッグ画面に移動"))
      : _createCommentVNode("", true)
  ], 64))
}