
import { DropdownOption } from '@/types/DropdownOption'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppInputDropdown',

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    options: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: null,
    },

    displayError: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  methods: {
    input($event: Event) {
      this.$emit('update:modelValue', ($event.target as HTMLInputElement).value)
    },
  },
})
