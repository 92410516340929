
import { defineComponent, reactive } from 'vue';

export default defineComponent({
    methods: {
        getFontSize(index: number) {
            if (index === 0) {
                return '12px'
            } else if (index === 1) {
                return '14px'
            } else {
                return '14px'
            }
        }
    },
    name: 'CustomLayout',
    setup() {
        const state = reactive({
            contentItems: [
            {
            "visibleItems": [
                "2024年9月7日(土) 開場15:00 / 開演16:00 <br> グッズ先行販売 13:00～(予定)",
                "千葉：浦安市民文化会館"
            ],
            "hiddenItems": [
                "（問）ホットスタッフ・プロモーション",
                "050-5211-6077（平日 12:00～18:00）"
            ]
            }
            ,
            {
            "visibleItems": [
                "2024年9月8日(日) 開場15:00 / 開演16:00 <br> グッズ先行販売 13:00～(予定)",
                "千葉：浦安市民文化会館"
            ],
            "hiddenItems": [
                "（問）ホットスタッフ・プロモーション",
                "050-5211-6077（平日 12:00～18:00）"
            ]
            }
            ,
            {
                "visibleItems": [
                "2024年9月20日(金) 開場18:00 / 開演19:00 <br> グッズ先行販売 15:00～(予定)",
                "熊本：熊本城ホール"
                ],
                "hiddenItems": [
                "（問）キョードー西日本",
                "0570-09-2424（平日･土曜 11:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年9月22日(日) 開場16:00 / 開演17:00 <br> グッズ先行販売 13:30～(予定)",
                "宮崎：宮崎市民文化ホール"
                ],
                "hiddenItems": [
                "（問）キョードー西日本",
                "0570-09-2424（平日･土曜 11:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月5日(土) 開場16:00 / 開演17:00",
                "新潟：新潟県民会館"
                ],
                "hiddenItems": [
                "（問）キョードー北陸チケットセンター",
                "025-245-5100（火 ～ 金 12:00～18:00/土日祝10:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月6日(日) 開場16:00 / 開演17:00",
                "石川：本多の森 北電ホール"
                ],
                "hiddenItems": [
                "（問）キョードー北陸チケットセンター",
                "025-245-5100（火 ～ 金 12:00～18:00/土日祝10:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月8日(火) 開場17:30 / 開演18:30",
                "長野：ホクト文化ホール 大ホール"
                ],
                "hiddenItems": [
                "（問）キョードー北陸チケットセンター",
                "025-245-5100（火 ～ 金 12:00～18:00/土日祝10:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月13日(日) 開場16:00 / 開演17:00",
                "栃木：宇都宮市文化会館"
                ],
                "hiddenItems": [
                "（問）ホットスタッフ・プロモーション",
                "050-5211-6077（平日 12:00～18:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月14日(月･祝) 開場16:00 / 開演17:00",
                "岩手：トーサイクラシックホール岩手（岩手県民会館）"
                ],
                "hiddenItems": [
                "（問）ノースロードミュージック",
                "022-256-1000（平日 11:00～16:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月19日(土) 開場16:00 / 開演17:00",
                "愛知：名古屋国際会議場センチュリーホール"
                ],
                "hiddenItems": [
                "（問）サンデーフォークプロモーション",
                "052-320-9100（12:00～18:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月20日(日) 開場15:00 / 開演16:00",
                "愛知：名古屋国際会議場センチュリーホール"
                ],
                "hiddenItems": [
                "（問）サンデーフォークプロモーション",
                "052-320-9100（12:00～18:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月27日(日) 開場16:00 / 開演17:00",
                "静岡：富士市文化会館ロゼシアター大ホール"
                ],
                "hiddenItems": [
                "（問）サンデーフォークプロモーション静岡",
                "054-284-9999（月 ～ 土 12:00～18:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年10月29日(火) 開場17:00 / 開演18:00",
                "兵庫：神戸国際会館こくさいホール"
                ],
                "hiddenItems": [
                "（問）サウンドクリエーター",
                "06-6357-4400（平日 12:00～15:00 ※祝日を除く）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月1日(金) 開場18:00 / 開演19:00",
                "北海道：札幌文化芸術劇場hitaru"
                ],
                "hiddenItems": [
                "（問）WESS",
                "info@wess.co.jp"
                ]
            },
            {
                "visibleItems": [
                "2024年11月2日(土) 開場15:00 / 開演16:00",
                "北海道：札幌文化芸術劇場hitaru"
                ],
                "hiddenItems": [
                "（問）WESS",
                "info@wess.co.jp"
                ]
            },
            {
                "visibleItems": [
                "2024年11月14日(木) 開場17:30 / 開演18:30",
                "東京：国立競技場代々木第一体育館"
                ],
                "hiddenItems": [
                "（問）ホットスタッフ・プロモーション",
                "050-5211-6077（平日 12:00～18:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月15日(金) 開場17:30 / 開演18:30",
                "東京：国立競技場代々木第一体育館"
                ],
                "hiddenItems": [
                "（問）ホットスタッフ・プロモーション",
                "050-5211-6077（平日 12:00～18:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月23日(土) 開場15:00 / 開演16:00",
                "山口：周南市文化会館"
                ],
                "hiddenItems": [
                "（問）YUMEBANCHI(広島)",
                "082-249-3571（平日 12:00～17:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月24日(日) 開場16:00 / 開演17:00",
                "広島：呉信用金庫ホール"
                ],
                "hiddenItems": [
                "（問）YUMEBANCHI(広島)",
                "082-249-3571（平日 12:00～17:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月26日(火) 開場17:00 / 開演18:00",
                "大阪：オリックス劇場"
                ],
                "hiddenItems": [
                "（問）サウンドクリエーター",
                "06-6357-4400（平日 12:00～15:00 ※祝日を除く）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月27日(水) 開場17:00 / 開演18:00",
                "大阪：オリックス劇場"
                ],
                "hiddenItems": [
                "（問）サウンドクリエーター",
                "06-6357-4400（平日 12:00～15:00 ※祝日を除く）"
                ]
            },
            {
                "visibleItems": [
                "2024年11月30日(土) 開場16:00 / 開演17:00",
                "岡山：倉敷市民会館"
                ],
                "hiddenItems": [
                "（問）YUMEBANCHI(岡山)",
                "086-231-3531（平日 12:00～17:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月7日(土) 開場16:00 / 開演17:00",
                "沖縄：那覇文化芸術劇場なはーと 大劇場"
                ],
                "hiddenItems": [
                "（問）PMエージェンシー",
                "098-898-1331（平日 11:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月8日(日) 開場15:00 / 開演16:00",
                "沖縄：那覇文化芸術劇場なはーと 大劇場"
                ],
                "hiddenItems": [
                "（問）PMエージェンシー",
                "098-898-1331（平日 11:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月13日(金) 開場17:00 / 開演18:00",
                "福岡：福岡サンパレス"
                ],
                "hiddenItems": [
                "（問）キョードー西日本",
                "0570-09-2424（平日･土曜 11:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月14日(土) 開場15:00 / 開演16:00",
                "福岡：福岡サンパレス"
                ],
                "hiddenItems": [
                "（問）キョードー西日本",
                "0570-09-2424（平日･土曜 11:00～15:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月21日(土) 開場16:00 / 開演17:00",
                "宮城：仙台サンプラザホール"
                ],
                "hiddenItems": [
                "（問）ノースロードミュージック",
                "022-256-1000（平日 11:00～16:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月22日(日) 開場15:00 / 開演16:00",
                "宮城：仙台サンプラザホール"
                ],
                "hiddenItems": [
                "（問）ノースロードミュージック",
                "022-256-1000（平日 11:00～16:00）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月28日(土) 開場16:00 / 開演17:00",
                "京都：ロームシアター京都メインホール"
                ],
                "hiddenItems": [
                "（問）サウンドクリエーター",
                "06-6357-4400（平日 12:00～15:00 ※祝日を除く）"
                ]
            },
            {
                "visibleItems": [
                "2024年12月29日(日) 開場15:00 / 開演16:00",
                "京都：ロームシアター京都メインホール"
                ],
                "hiddenItems": [
                "（問）サウンドクリエーター",
                "06-6357-4400（平日 12:00～15:00 ※祝日を除く）"
                ]
            }
            ],
            expandedRows: {} as Record<number, boolean>, 
        });

        const toggleRow = (rowIndex: number) => {
            state.expandedRows[rowIndex] = !state.expandedRows[rowIndex];
        };

        return {
            contentItems: state.contentItems,
            expandedRows: state.expandedRows,
            toggleRow,
        };
    },
});
