
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppButtonPrimary',

  props: {
    active: {
      type: Boolean,
      default: true,
    },

    showCaret: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['button'],
})
