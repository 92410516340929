
import { defineComponent } from 'vue'
import Coupon from './Coupon.vue'

export default defineComponent({
  name: 'Coupons',

  components: {
    Coupon,
  },

  props: {
    isPremiumMember: {
      type: Boolean,
      required: true,
    },
    createdAt: {
      type: String,
      default: null,
    },
  },

  methods: {
    isRegistrationPeriod() {
      const dateToCheck = new Date(this.createdAt)
      const startDate = new Date('2024-04-11T00:00:00')
      const endDate = new Date('2024-05-04T23:59:59')
      return dateToCheck >= startDate && dateToCheck < endDate
    },
  },
})
