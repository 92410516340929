
import { defineComponent, PropType } from 'vue'
import TourSelectButtonTourDate from '@/components/tour_select/TourSelectButtonTourDate.vue'
import { Tour } from '@/types/Tour'

export default defineComponent({
  name: 'TourSelectStepASelect',

  components: {
    TourSelectButtonTourDate,
  },

  props: {
    tourOptions: {
      type: Array as PropType<Tour[]>,
      required: true,
    },
  },

  emits: ['select'],
})
