
import { defineComponent, PropType } from 'vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'
import TheHeaderWithCloseButton from '@/components/TheHeaderWithCloseButton.vue'
import TourSelectStepASelect from '@/components/tour_select/TourSelectStepASelect.vue'
import TourSelectStepBConfirm from '@/components/tour_select/TourSelectStepBConfirm.vue'
import { Tour } from '@/types/Tour'

const tourSelectSteps = [
  'tour_select_step_a_select',
  'tour_select_step_b_confirm',
] as const
type TourSelectStep = typeof tourSelectSteps[number]

export default defineComponent({
  name: 'TourSelect',

  components: {
    AppButtonPrimary,
    AppButtonSecondary,
    TheHeaderWithCloseButton,
    TourSelectStepASelect,
    TourSelectStepBConfirm,
  },

  props: {
    selectedTourIds: {
      type: Array as PropType<number[]>,
      required: true,
    },

    tourOptions: {
      type: Array as PropType<Tour[]>,
      required: true,
    },
  },

  emits: ['navigate', 'saveSelectedTourIds'],

  data() {
    return {
      selectedTourIdsNew: [] as number[],
      tourOptionsCopy: [] as Tour[],

      currentStepIndex: 0,

      headingTitle: ['ツアー参加日のご選択', 'ツアー参加日のご確認'],
      headingDescription: [
        'お客様が参加されるツアー日をご選択ください。（ 複数可 ）',
        '以下のツアー参加日に間違いがないかご確認の上「登録する」ボタンを押してください',
      ],
    }
  },

  computed: {
    isTourSelectStepASelect() {
      return this.currentStepName === 'tour_select_step_a_select'
    },

    isTourSelectStepBConfirm() {
      return this.currentStepName === 'tour_select_step_b_confirm'
    },

    currentStepName(): TourSelectStep {
      return tourSelectSteps[this.currentStepIndex]
    },

    totalSteps() {
      return tourSelectSteps.length
    },

    currentHeadingTitle() {
      return this.headingTitle[this.currentStepIndex]
    },

    currentHeadingDescription() {
      return this.headingDescription[this.currentStepIndex]
    },

    isFormValid() {
      return this.selectedTourIdsNew.length > 0
    },

    tourOptionsComputed() {
      return this.tourOptionsCopy
        .map((tour) => {
          const { tourId } = tour
          return {
            ...tour,
            checked: this.selectedTourIdsNew.includes(tourId),
          }
        })
        .filter((v) => !v.hasPast)
    },
  },

  mounted() {
    this.selectedTourIdsNew = this.selectedTourIds.map((v) => v)
    this.tourOptionsCopy = this.tourOptions.map((v) => v)
  },

  methods: {
    close() {
      this.$emit('navigate', 'top_screen')
    },

    selectTour(tourId: number) {
      if (this.selectedTourIdsNew.includes(tourId)) {
        this.selectedTourIdsNew = this.selectedTourIdsNew.filter(
          (v) => v !== tourId
        )
      } else {
        this.selectedTourIdsNew.push(tourId)
      }
    },

    next() {
      if (!this.isFormValid) {
        return
      }
      if (this.currentStepIndex < this.totalSteps - 1) {
        window.scrollTo(0, 0)
        this.currentStepIndex++
      } else {
        this.$emit('saveSelectedTourIds', this.selectedTourIdsNew)
      }
    },

    previous() {
      if (this.currentStepIndex > 0) {
        window.scrollTo(0, 0)
        this.currentStepIndex--
      }
    },

    padWithZero(value: string | number) {
      return String(value).padStart(2, '0')
    },
  },
})
