import { CouponBannerUser } from '@/types/User'
import { axios } from './axios'

interface CouponUserGetParams {
  memberNumber: string
}

export const retrieveCouponUser = async (
  memberNumber: string
): Promise<CouponBannerUser | undefined> => {
  const params: CouponUserGetParams = { memberNumber }
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_COUPON_USERS_API}/_`,
      {
        params,
      }
    )
    return response.data
  } catch (error) {
    console.error('retrieveUser', error)
    throw error
  }
}

interface CouponUserPostParams {
  memberNumber: string
  useCoupon: boolean
}

export const saveCouponUser = async (
  memberNumber: string,
  useCoupon: boolean
): Promise<CouponBannerUser | undefined> => {
  const params: CouponUserPostParams = { memberNumber, useCoupon }
  try {
    const response = await axios.patch<CouponBannerUser>(
      `${process.env.VUE_APP_COUPON_USERS_API}`,
      params
    )
    return response.data
  } catch (error) {
    console.error('saveUser', error)
    throw error
  }
}
