
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QuestionnaireFillOutGenderInput',

  props: {
    gender: {
      type: String,
      required: true,
      validator: (value: string) =>
        ['', 'male', 'female', 'other'].includes(value),
    },

    displayError: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['check'],

  computed: {
    isMale() {
      return this.gender === 'male'
    },

    isFemale() {
      return this.gender === 'female'
    },

    isOther() {
      return this.gender === 'other'
    },
  },
})
