
import prefectureOptions from '@/constants/prefectureOptions'
import { NewQuestionnaire } from '@/types/Questionnaire'
import { defineComponent, PropType } from 'vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'

export default defineComponent({
  name: 'QuestionnaireFillOutStepBConfirm',

  components: {
    AppButtonPrimary,
    AppButtonSecondary,
  },

  props: {
    questionnaire: {
      type: Object as PropType<NewQuestionnaire>,
      required: true,
    },
  },

  emits: ['next', 'previous'],

  computed: {
    prefectureName() {
      return (
        (
          prefectureOptions.find(
            ({ id }) => id === this.questionnaire.prefecture
          ) || {}
        ).name || ''
      )
    },

    genderName() {
      switch (this.questionnaire.gender) {
        case 'male':
          return '男性'
        case 'female':
          return '女性'
        case 'other':
          return 'その他'
        default:
          return ''
      }
    },
  },

  methods: {
    next() {
      this.$emit('next')
    },

    previous() {
      this.$emit('previous')
    },
  },
})
