
import { defineComponent, PropType } from 'vue'
import TourSelectSelectedTourDate from '@/components/tour_select/TourSelectSelectedTourDate.vue'
import { Tour } from '@/types/Tour'

export default defineComponent({
  name: 'TourSelectStepBConfirm',

  components: {
    TourSelectSelectedTourDate,
  },

  props: {
    tourOptions: {
      type: Array as PropType<Tour[]>,
      required: true,
    },
  },

  computed: {
    selectedTours() {
      return this.tourOptions.filter(({ checked }) => checked)
    },
  },
})
