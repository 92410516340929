import '@/assets/App.css'
import { createApp } from 'vue'
import App from './App.vue'

import { defineRule } from 'vee-validate'
import { getDaysInMonth } from 'date-fns'

defineRule('required', (value: string) => {
  if (!value || value === '') {
    return 'This is required'
  }

  return true
})

defineRule('phoneNumber', (value: string) => {
  if (!/^[0-9-]{9,14}$/i.test(value)) {
    return `Should be a valid phone number`
  }

  return true
})

defineRule('email', (value: string) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return `Should be a valid email address`
  }

  return true
})

defineRule('year', (value: string) => {
  if (value && !/^[0-9]{4}$/i.test(value)) {
    return `Should be a valid 4 digit year`
  }

  return true
})

defineRule('month', (value: string) => {
  if (value && (!/^[0-9]{1,2}$/i.test(value) || parseInt(value, 10) > 12)) {
    return `Should be a valid month`
  }
  return true
})

// eslint-disable-next-line no-unused-vars
defineRule('date', (value: string, _, ctx) => {
  const { year, month } = ctx.form
  if (
    value &&
    year &&
    month &&
    (!/^[0-9]{1,2}$/i.test(value) ||
      parseInt(value, 10) >
        getDaysInMonth(
          new Date(year as number, parseInt(month as string, 10) - 1)
        ))
  ) {
    return 'Should be a valid date'
  }
  return true
})

createApp(App).mount('#app')
