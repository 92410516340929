
import { defineComponent } from 'vue'
// import { format } from "date-fns";
import prefectureOptions from '@/constants/prefectureOptions'
import { Form, FormValidationResult, ValidationOptions } from 'vee-validate'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'
import AppInputText from '@/components/AppInputText.vue'
import AppInputDropdown from '@/components/AppInputDropdown.vue'
import AppInputCheckbox from '@/components/AppInputCheckbox.vue'
import QuestionnaireFillOutGenderInput from '@/components/questionnaire_fill_out/QuestionnaireFillOutGenderInput.vue'
import { NewQuestionnaire } from '@/types/Questionnaire'

export default defineComponent({
  name: 'QuestionnaireFillOutStepAFillOut',

  components: {
    Form,
    AppButtonPrimary,
    AppButtonSecondary,
    AppInputText,
    AppInputDropdown,
    AppInputCheckbox,
    QuestionnaireFillOutGenderInput,
  },

  emits: ['previous', 'next'],

  data() {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      prefecture: '',
      year: '',
      month: '',
      date: '',
      gender: '',
      confirmsIsInGoodHealth: false,
      agreesToPrivatePolicy: false,

      touched: {
        name: false,
        email: false,
        phoneNumber: false,
        year: false,
        month: false,
        date: false,
        prefecture: false,
        gender: false,
        confirmsIsInGoodHealth: false,
        agreesToPrivatePolicy: false,
      },
    }
  },

  computed: {
    isPrefectureValid() {
      return this.prefecture !== ''
    },

    isGenderValid() {
      return this.gender !== ''
    },

    formattedBirthday() {
      return `${this.year || ''}/${this.month || ''}/${this.date || ''}`
    },

    prefectureOptions() {
      return prefectureOptions
    },

    prefectureName() {
      return (
        (prefectureOptions.find(({ id }) => id === this.prefecture) || {})
          .name || ''
      )
    },

    genderName() {
      switch (this.gender) {
        case 'male':
          return '男性'
        case 'female':
          return '女性'
        case 'other':
          return 'その他'
        default:
          return ''
      }
    },
  },

  watch: {
    prefecture: function () {
      this.touched.prefecture = true
    },
  },

  methods: {
    toggleGender(value: string) {
      if (this.gender === value) {
        this.gender = ''
      } else {
        this.gender = value
      }
      this.touched.gender = true
    },

    toggleConfirmsIsInGoodHealth() {
      this.confirmsIsInGoodHealth = !this.confirmsIsInGoodHealth
      this.touched.confirmsIsInGoodHealth = true
    },

    toggleAgreesToPrivatePolicy() {
      this.agreesToPrivatePolicy = !this.agreesToPrivatePolicy
      this.touched.agreesToPrivatePolicy = true
    },

    canSave(isValid: boolean) {
      return (
        isValid &&
        this.isPrefectureValid &&
        this.isGenderValid &&
        this.confirmsIsInGoodHealth &&
        this.agreesToPrivatePolicy
      )
    },

    async proceed(
      isValid: boolean,
      setTouched: (fields: Partial<Record<string, boolean>>) => void,
      validate: {
        (opts?: Partial<ValidationOptions> | undefined): Promise<
          FormValidationResult<Record<string, any>>
        >
        (): any
      }
    ) {
      const refs = this.$refs
      if (refs && refs.focus) {
        ;(refs.focus as HTMLElement).focus()
      }
      setTouched({
        name: true,
        temperature: true,
        email: true,
        phoneNumber: true,
        year: true,
        month: true,
        date: true,
      })
      await validate()
      this.touched = {
        name: true,
        email: true,
        phoneNumber: true,
        year: true,
        month: true,
        date: true,
        prefecture: true,
        gender: true,
        confirmsIsInGoodHealth: true,
        agreesToPrivatePolicy: true,
      }
      if (this.canSave(isValid)) {
        this.year = this.padWithZero(this.year)
        this.month = this.padWithZero(this.month)
        this.date = this.padWithZero(this.date)
        const {
          name,
          email,
          phoneNumber,
          prefecture,
          gender,
          confirmsIsInGoodHealth,
          agreesToPrivatePolicy,
        } = this
        const data: Omit<NewQuestionnaire, 'tourId'> = {
          name,
          email,
          phoneNumber,
          birthday: this.formattedBirthday,
          prefecture,
          gender,
          confirmsIsInGoodHealth,
          agreesToPrivatePolicy,
        }
        this.$emit('next', data)
      }
    },

    padWithZero(value: string) {
      return String(value).padStart(2, '0')
    },
  },
})
